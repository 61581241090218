import { AppContext } from "@components/contexts/AppContext";
import {
  rsiCapgemini,
  rsiDominos,
  rsiItv,
  rsiRakuten,
  rsiTui,
  rsiWise,
} from "@components/login/logos";
import { ListItem, PAGE_CONFIG } from "@libs/products";
import {
  Flexbox,
  Heading,
  Icon,
  RedsiftColorRedN,
  Text,
} from "@redsift/design-system";
import { rsiRedSiftHorizontal } from "@redsift/icons";
import { ReactElement, useContext } from "react";
import styled from "styled-components";

const Container = styled(Flexbox)`
  border-radius: calc(var(--redsift-border-radius) * 2);
  border: 1px solid var(--redsift-color-grey-l1);
  box-shadow: 5px 5px 0 0 rgba(0, 0, 0, 0.25);
  overflow: hidden;
`;

const BackgroundStrip = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  pointer-events: none;
`;

const LoginScreen = ({ LoginPanel }: { LoginPanel: ReactElement }) => {
  const ctx = useContext(AppContext);
  const product = ctx.getProduct();
  const config = PAGE_CONFIG[product] ?? PAGE_CONFIG["pulse-platform"];

  const { color, logos, PartnerBanner, title, body, Quote, Intro, conclusion } =
    config;

  return (
    <Flexbox
      padding="22px 60px 74px"
      background="var(--redsift-color-presentation-grey-lighterer)"
      flexDirection="column"
      position="relative"
      gap="22px"
    >
      <Icon icon={rsiRedSiftHorizontal} size={{ width: 168 }} />
      <BackgroundStrip>
        <svg
          width="700"
          height="94"
          viewBox="0 0 700 94"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M846.983 -692.157H564.018L0 93.6833H282.965L846.983 -692.157Z"
            fill={color === RedsiftColorRedN ? "#FF0000" : color}
            fillOpacity={color === RedsiftColorRedN ? "1" : "0.6"}
          />
          <path
            d="M1411 -1478H1128.04L564.018 -692.157H846.983L1411 -1478Z"
            fill={color === RedsiftColorRedN ? "#FF0000" : color}
            fillOpacity={color === RedsiftColorRedN ? "1" : "0.6"}
          />
        </svg>
      </BackgroundStrip>

      <Container
        padding="40px 32px"
        background="var(--redsift-color-neutral-white)"
        flexDirection="column"
        position="relative"
        gap="80px"
        maxWidth="1254px"
        margin="0 auto"
        height="100%"
      >
        <BackgroundStrip>
          <svg
            width="700"
            height="2000"
            viewBox="0 0 700 2000"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              fill={color}
              height="2000px"
              width="226px"
              style={{ transform: "rotate(-37deg)" }}
            />
          </svg>
        </BackgroundStrip>
        <Flexbox
          justifyContent="space-between"
          flexDirection="row"
          gap="40px"
          alignItems="stretch"
        >
          <Flexbox
            flexDirection="column"
            flex="1"
            justifyContent="center"
            minWidth="50%"
            maxWidth="50%"
          >
            {logos ? (
              <Flexbox flexDirection="row" marginBottom="16px" gap="48px">
                {logos.map((logo, index) => (
                  <Icon key={index} icon={logo} size={{ height: 46 }} />
                ))}
              </Flexbox>
            ) : null}
            {PartnerBanner ? <PartnerBanner /> : null}
            <Heading
              as="h1"
              fontSize="42px"
              lineHeight="55px"
              marginBottom="32px"
            >
              {title}
            </Heading>
            <Flexbox flexDirection="column">
              {Intro ? <Intro /> : null}
              {body ? (
                <Flexbox flexDirection="column">
                  {body.map(({ title, description }) => (
                    <ListItem
                      key={title.toString()}
                      title={title}
                      description={description}
                    />
                  ))}
                </Flexbox>
              ) : null}
            </Flexbox>
            {Quote ? <Quote /> : null}
          </Flexbox>
          <Flexbox
            maxWidth="520px"
            flexDirection="column"
            padding="32px"
            flex="1"
            style={{
              borderRadius: "calc(var(--redsift-border-radius) * 2)",
              border: "1px solid var(--redsift-color-grey-l1)",
              boxShadow: "5px 5px 0 0 rgba(0, 0, 0, 0.25)",
              background: "white",
              overflow: "hidden",
              zIndex: 1,
            }}
          >
            {LoginPanel}
          </Flexbox>
        </Flexbox>
      </Container>
      <Text alignSelf="center" maxWidth="700px" style={{ textAlign: "center" }}>
        {conclusion}
      </Text>
      <Flexbox
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="center"
        alignItems="center"
        gap="32px"
      >
        <Icon size={{ height: 64 }} icon={rsiDominos} />
        <Icon size={{ height: 64 }} icon={rsiRakuten} />
        <Icon size={{ height: 64 }} icon={rsiItv} />
        <Icon size={{ height: 64 }} icon={rsiTui} />
        <Icon size={{ height: 64 }} icon={rsiWise} />
        <Icon size={{ height: 64 }} icon={rsiCapgemini} />
      </Flexbox>
    </Flexbox>
  );
};

export default LoginScreen;
